import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Составление документов | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Юристы нашей компании готовы подсказать, как правильно составить договор, помочь вам в этом, а также проанализировать уже разработанные документы." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/sostavlenie-dokumentov'
				name="Составление документов | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Юристы нашей компании готовы подсказать, как правильно составить договор, помочь вам в этом, а также проанализировать уже разработанные документы."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Составление документов" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Составление документов"}
			
				html={

				`
				<p class="TextText">
                	<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Часто случается так, что некорректно составленный договор приводит либо к несостоявшейся сделке, либо к судебному разбирательству. При этом обычно страдает тот, кто был инициатором заключения сделки. Избежать этой проблемы вполне реально при юридически грамотном и безопасном составлении и оформлении договоров, которые будут выполнены при соблюдении всех норм российского законодательства. Для того чтобы иметь уверенность в правильности написания каждой строки документа, необходима своевременная консультация специалистов.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Юристы нашей компании готовы подсказать, как правильно составить договор, помочь вам в этом, а также проанализировать уже разработанные документы. Весь процесс проверки договоров будет проходить в соответствии с юридическими нормами и будет включать в себя все этапы составления и заключения документа – от начальной стадии проекта до заключительной (подписание сторонами документов).  Кроме составления договора наши юристы готовы помочь вам в судебном или досудебном урегулировании споров, в случае, если пункты уже подписанного договора не выполнены. Наша компания создана для того, чтобы частные и юридические лица не боялись заключать выгодные сделки, чтобы ваша ситуация в любой момент была под контролем. Мы разрешим любую проблему, какой бы она ни была.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Составить договор – дело нехитрое, требующее определенных знаний и навыков, поэтому мы готовы привести следующие доводы необходимости обращения за данной услугой к специалистам. Во-первых, в случае ошибки в договоре, он может быть признан недействительным, а потому вы не получите в итоге желаемого результата. Так, например, даже составление договора купли продажи автомобиля требует четкой проверки. 
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					С помощью наших сотрудников вы можете быть уверены за качественное и своевременное составление документов. Все пункты в них будут проработаны в соответствии с актуальным законодательством, а положения договора отразят права и обязанности сторон, а также предусмотрят будущие проблемы. На первом месте для нас стоит защита интересов клиента, а потому, если вы обращаетесь в нашу компанию, вы останетесь довольны результатом и условиями обслуживания. Какая бы помощь в составлении договоров вам ни требовалась: составление договора аренды или купли-продажи – мы предупредим возможные ошибки, а также оградим вас от последующих неприятностей.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
